export const useShortName = (name?: string): string => {
  if (!name) {
    return "G";
  }

  const words = name.split(" ");

  if (words.length === 2) {
    return words[0][0] + words[1][0];
  } else {
    return words[0][0];
  }
};
